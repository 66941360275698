import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MissingTranslationHandler, TranslateModule} from '@ngx-translate/core';
import {
    NgbDatepickerI18n,
    NgbDatepickerModule,
    NgbModule,
    NgbNavModule,
    NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {ShellComponent} from './shell.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EditorPanelService} from '@app/services/editor-panel.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BaseDialogService} from '@app/shared/_modals/base-dialog.service';
import {ClickOutsideModule} from 'ng-click-outside';
import {ResizableModule} from 'angular-resizable-element';
import {CustomDatepickerI18n} from '@app/datepicker-i18n';
import {AngularMaterialModule} from '@app/shared/_modules/angular-material.module';
import {MyMissingTranslationHandler} from '@app/core/MyMissingTranslationHandler';
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatBadgeModule} from "@angular/material/badge";
import {HeaderModule} from "@app/shell/header/header.module";
import {SidebarModule} from "@app/shell/sidebar/Sidebar.module";
import {DisplayNonPlannedModule} from "@app/editor/non-planned-itemlist/non-planned-itemlist.module";
import {RouterModule} from "@angular/router";
import {DisplayFilterEditorModule} from "@app/editor/display-filter-editor/display-filter-editor.module";
import {UpdatesNotificationModule} from "@app/shell/updates-notification/UpdatesNotification.module";
import {DynamicChildLoaderDirectiveModule} from "@app/directives/DynamicChildLoaderDirective.module";
import {
    PrioritizationDialogModule
} from "@app/pages/displays/display-kanban/prioritization-dialog/PrioritizationDialog.module";
import {AppointmentDialogModule} from "@app/shared/_modals/appointment-dialog/AppointmentDialog.module";
import {CardArchivedDialogModule} from "@app/shared/_modals/case-archived-dialog/CaseArchivedDialog.module";
import {
    ChangeProjectTypeDialogModule
} from "@app/shared/_modals/change-project-type-dialog/ChangeProjectTypeDialog.module";
import {ConfirmationDialogModule} from "@app/shared/_modals/confirmation-dialog/ConfirmationDialog.module";
import {CopyMilestoneDialogModule} from "@app/shared/_modals/copy-milestone-dialog/CopyMilestoneDialog.module";
import {
    CopyProjectCasesDialogModule
} from "@app/shared/_modals/copy-project-cases-dialog/CopyProjectCasesDialog.module";
import {CopyProjectDialogModule} from "@app/shared/_modals/copy-project-dialog/CopyProjectDialog.module";
import {CreatePhaseDialogModule} from "@app/shared/_modals/create-phase-dialog/CreatePhaseDialog.module";
import {
    CreateProjectRedirectPlanningDialogModule
} from "@app/shared/_modals/create-project-redirect-planning-dialog/CreateProjectRedirectPlanningDialog.module";
import {
    CreateProjectTemplateDialogModule
} from "@app/shared/_modals/create-project-template-dialog/CreateProjectTemplateDialog.module";
import {
    CreateStatusRuleDialogModule
} from "@app/shared/_modals/create-status-rule-dialog/CreateStatusRuleDialog.module";
import {ErrorDialogModule} from "@app/shared/_modals/error-dialog/ErrorDialog.module";
import {MilestoneArchiveDialogModule} from "@app/shared/_modals/milestone-archive-dialog/MilestoneArchiveDialog.module";
import {
    MissingDepartmentsDialogModule
} from "@app/shared/_modals/missing-departments-dialog/MissingDepartmentsDialog.module";
import {
    MoveMilestoneDeadlineDialogModule
} from "@app/shared/_modals/move-milestone-deadline-dialog/MoveMilestoneDeadlineDialog.module";
import {
    MoveProjectDeadlineDialogModule
} from "@app/shared/_modals/move-project-deadline-dialog/MoveProjectDeadlineDialog.module";
import {RichTextEditorDialogModule} from "@app/shared/_modals/rich-text-editor-dialog/RichTextEditorDialog.module";
import {
    SelectProjectMilestoneDialogModule
} from "@app/shared/_modals/select-project-milestone-dialog/SelectProjectMilestoneDialog.module";
import {TextAreaDialogModule} from "@app/shared/_modals/text-area-dialog/TextAreaDialog.module";
import {TaskEditorLoaderModule} from "@app/editor/task-editor-loader/TaskEditorLoader.module";
import {ProjectEditorLoaderModule} from "@app/editor/project-editor-loader/ProjectEditorLoader.module";
import {MilestoneEditorLoaderModule} from "@app/editor/milestone-editor-loader/MilestoneEditorLoader.module";
import {TodoEditorLoaderModule} from "@app/editor/todo-editor-loader/TodoEditorLoader.module";
import {DisplayProjectDetailsModule} from "@app/pages/displays/display-project-details/display-project-details.module";
import {
    MoveMilestoneTasksProjectDialogModule
} from '@app/shared/_modals/move-milestone-tasks-project-dialog/MoveMilestoneTasksProjectDialog.module';
import {ChangeTaskTypeDialogModule} from '@app/shared/_modals/change-task-type-dialog/ChangeTaskTypeDialog.module';
import {FocusableService} from "@app/core/Focusable/Focusable.service";
import {ProjectDeleteDialogModule} from "@app/shared/_modals/project-delete-dialog/ProjectDeleteDialog.module";
import {RestoreItemDialogModule} from "@app/shared/_modals/retore-item-dialog/RestoreItemDialog.module";
import {MilestoneDeleteDialogModule} from "@app/shared/_modals/milestone-delete-dialog/MilestoneDeleteDialog.module";

@NgModule({
    imports: [
        ClickOutsideModule,
        TranslateModule.forRoot(
            {
                missingTranslationHandler: {
                    provide: MissingTranslationHandler,
                    useClass: MyMissingTranslationHandler,
                },
            }
        ),
        CommonModule,
        ResizableModule,
        BrowserAnimationsModule,
        FormsModule,
        DragDropModule,
        ReactiveFormsModule,
        NgbModule,
        NgbTooltipModule,
        NgbDatepickerModule,
        NgbNavModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatSnackBarModule,
        MatBadgeModule,
        AngularMaterialModule,
        HeaderModule,
        SidebarModule,
        RouterModule,
        UpdatesNotificationModule,
        DynamicChildLoaderDirectiveModule,

        // Dynamically added displays
        DisplayProjectDetailsModule,

        // Dynamically added Components
        TaskEditorLoaderModule,
        MilestoneEditorLoaderModule,
        ProjectEditorLoaderModule,
        TodoEditorLoaderModule,
        DisplayFilterEditorModule,
        DisplayNonPlannedModule,

        // Dynamically added Modals
        PrioritizationDialogModule,
        AppointmentDialogModule,
        CardArchivedDialogModule,
        ChangeProjectTypeDialogModule,
        ChangeTaskTypeDialogModule,
        ConfirmationDialogModule,
        CopyMilestoneDialogModule,
        CopyProjectCasesDialogModule,
        CopyProjectDialogModule,
        CreatePhaseDialogModule,
        CreateProjectRedirectPlanningDialogModule,
        CreateProjectTemplateDialogModule,
        CreateStatusRuleDialogModule,
        ErrorDialogModule,
        MilestoneArchiveDialogModule,
        MissingDepartmentsDialogModule,
        MoveMilestoneDeadlineDialogModule,
        MoveProjectDeadlineDialogModule,
        MoveMilestoneTasksProjectDialogModule,
        RichTextEditorDialogModule,
        SelectProjectMilestoneDialogModule,
        TextAreaDialogModule,
        ProjectDeleteDialogModule,
        RestoreItemDialogModule,
        MilestoneDeleteDialogModule,

    ],
    declarations: [ // DO NOT ADD MORE THAN ONE DECLARATION!
        ShellComponent,
    ],
    exports: [

    ],
    providers: [
        EditorPanelService,
        BaseDialogService,
        FocusableService,
        {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
        //{provide: NgbDateParserFormatter, useClass: MomentDateFormatter}
    ]
})
export class ShellModule {
}
