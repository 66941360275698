import {Project, ProjectUserTypes} from '@app/core/models/Project';
import {User} from '@app/core/models/User';
import {Milestone} from '@app/core/models/Milestone';
import {TaskType} from '@app/core/models/TaskType';
import {ListConfiguration} from '@app/shared/_ui/lists/ListConfiguration';
import {Department} from '@app/core/models/Department';
import {Task} from '@app/core/models/Task';
import {TaskFetchRequest} from '@app/shared/_ui/lists/task-list/TaskFetcher';
import * as moment from 'moment';
import {AppInjector} from '@app/services/app-injector.service';
import {TaskDeadlineTypes, TasksUserAcceptanceStatus, TaskUserTypes} from '@app/constants';
import {CardInterface, CardItem} from '@app/shared/_ui/cards/CardItem';
import {CreateItemSourceConfiguration} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceConfiguration';
import {CreateItemPreset} from '@app/shared/_ui/create-item-dropdown/CreateItemPreset';
import {Deadline} from '@app/core/models/Deadline';
import {CreateItemInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemInterface';
import {CreateItemSourceInterface} from '@app/shared/_ui/create-item-dropdown/CreateItemSourceInterface';
import {EventService} from "@app/services/event.service";
import {Subscription} from "rxjs";
import {CSVExportOptionInterface} from "@app/export/csv/CSVExportOptionInterface";
import {CardTaskConfiguration} from "@app/shared/_ui/cards/medium/card-task/card-task-configuration";
import {CdkDropEvent} from "@app/interfaces/CdkDropEvent";
import {ListDragInterface} from "@app/interfaces/ListDragInterface";
import {Api} from "@app/core/http/Api/Api";
import {BaseDialogService} from "@app/shared/_modals/base-dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {UsersService} from "@app/services/users.service";
import {BaseApi} from "@app/core/http/Api/BaseApi";
import {FilterGlobalService} from "@app/services/FilterGlobalService/filter-global.service";
import {EventEmitter} from "@angular/core";
import {CreatePreset} from "@app/shared/_ui/create-item-dropdown/Presets/CreatePreset";

export class TaskListConfiguration<T = Task> extends ListConfiguration<T> implements CreateItemInterface<Task>,
    CreateItemSourceInterface, CardInterface<Task>, ListDragInterface<T> {

    private enabled: boolean = true;

    // UI
    private cardItemDragRemoveValidator: (cardItem: CardItem<Task>) => boolean;

    // Filter
    private taskType: TaskType;
    private project?: Project;
    private projects?: Project[];
    private user?: User;
    private milestone?: Milestone;
    private archived?: boolean;
    private notArchivedForUserId?: number;
    private avoidIds?: number[];
    private plannedBetween: Date[]; // [<start>, <end>]
    private plannedValidator: (deadline: Deadline) => boolean;
    private plannedValidatorDate: () => Date;
    private deadlineBetween: Date[];
    private deadlineValidator: (task: Task, deadlines: Deadline[]) => boolean;
    private createdBetween: Date[];
    private archivedBetween: Date[];
    private avoidTaskTypeIds: number[];
    private department: Department;
    private useGlobalFilter: boolean;
    private softDeadline: boolean = null;
    private search: string;
    private orArchivedSince: Date;
    private userTypeId: number;
    private acceptanceStatus = [TasksUserAcceptanceStatus.New, TasksUserAcceptanceStatus.Approved];
    private planningDeadlineId: number;
    private canCopy: boolean = null;
    private hasMilestone: boolean = null;
    private taskDeadlineTypes: number[];
    private taskTypeIds: number[];
    private open: boolean = null;
    private hasNonDeadline: boolean;
    private categoryIds?: number[];
    private cardConfiguration_excludedCategoryIds: number[];
    private mainStatusStatusIds?: number[];
    private hasNotes: boolean = null;
    private requireRelatedMilestone: boolean;
    private hasHands: boolean | null = null;
    private hasStars: boolean | null = null;

    // Limit & Order
    // Opdateret standardsortering: https://podio.com/klartboard/softwareudvikling/apps/stories/items/738
    private orderBy: string[][] = [['main_status.status_id', 'asc'], ['tasks_deadline.deadline.date', 'null'], ['tasks_deadline.deadline.date', 'asc'], ['title', 'asc']];
    // Order requirements (When an order require a filter to be set).
    private sort_CategoryTypeId: number;
    private sort_DeadlineTypeId: number;
    private sort_DeadlineValidator: (task: Task) => boolean;

    // Show/Hide
    private showProjectMiniCard = true;
    private cardIsMini = false;
    private showMilestoneMiniCard = false;
    private shownEstimateTypeId: number;
    public showChangeType = true;

    // Drag Drop
    private enableDragDropAutoMove: boolean = false;
    public isSortingEnabled: boolean;

    // Data Provider
    private dataSource: TaskFetchRequest;

    // Hooks
    private beforeAddHook: ((item: CardItem<Task>) => void);
    private customAllowCardItemDropFunction: (cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>, result: (allow: boolean) => void) => void;
    private customAllowCardItemEnterFunction: (cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>) => boolean;
    private customOnCardItemDragAddFunction: (cardItem: CardItem<Task>, configuration: TaskListConfiguration, previousIndex?: number, nextIndex?: number) => void;
    private customOnCardItemDragSavedFunction: () => void;
    private prepareCreateItemSourceHook: (createItemConfiguration: CreateItemSourceConfiguration) => void;

    // Events
    public onItemUpdatedEvent = new EventEmitter<CardItem<Task>>();
    public onItemRemovedEvent = new EventEmitter<CardItem<Task>>();

    constructor() {
        super();
        this.modelClass = Task.name;

        this.createItemConfiguration = new CreateItemSourceConfiguration();
        this.createItemConfiguration.showMilestone = false;
        this.createItemConfiguration.showTodo = false;
        this.createItemConfiguration.showProjects = false;
        this.createItemConfiguration.showTasks = true;
        this.createItemConfiguration.sourceInterface = this;
        this.createItemPreset = new CreateItemPreset();
        this.createItemPreset.createTaskInterface = this;
    }

    public validate(task: Task): boolean {
        let debug = false;
        if (debug) {
            console.warn('validate() : ', task, this);
        }
        if (this.getTaskType()) {
            if (this.getTaskType().id != task.task_type_id) {
                if (debug) {
                    console.warn('ignored, cause of taskType');
                }
                return false;
            }
        }
        if (this.getTaskTypeIds()) {
            if (!this.getTaskTypeIds().includes(task.task_type_id)) {
                if (debug) {
                    console.warn('ignored, cause of taskTypeIds : ', task, this.getTaskTypeIds());
                }
                return false;
            }
        }
        if (this.getAvoidTaskTypeIds()) {
            if (this.getAvoidTaskTypeIds().includes(task.task_type_id)) {
                if (debug) {
                    console.warn('ignored, cause of avoidTaskType');
                }
                return false;
            }
        }
        if (this.getMilestone()) {
            if (task.milestones == null || task.milestones.find(item => item.id == this.getMilestone().id) == null) {
                if (debug) {
                    console.warn('ignored, cause of milestone : ', this.getMilestone().id);
                }
                return false;
            }
        }
        if (this.getProject()) {
            if (task.projects == null || task.projects.find(item => item.id == this.getProject().id) == null) {
                if (debug) {
                    console.warn('ignored, cause of project : ', this.getProject().id);
                }
                return false;
            }
        }
        if (this.getUser()) {
            if (task.tasks_users == null
                || task.tasks_users.find(item => {
                    const isUser = item.user_id == this.getUser().id;
                    const isType = !this.getUserTypeId() || this.getUserTypeId() == item.task_user_type_id;
                    return isUser && isType;
                }) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of no tasksUsers');
                }
                return false;
            }
            if (this.getPlannedBetween()) {
                let user = task.findParticipant(this.getUser().id);
                if (user == null) {
                    if (debug) {
                        console.warn('ignored, cause of no participant');
                    }
                    return false;
                }
                if (user.deadline == null || !user.deadline.isBetween(this.getPlannedBetween()[0], this.getPlannedBetween()[1])) {
                    if (debug) {
                        console.warn('ignored, cause of plannedBetween');
                    }
                    return false;
                }
            }
            if (this.getPlannedValidator()) {
                const user = task.findParticipant(this.getUser().id);
                if (user == null) {
                    if (debug) {
                        console.warn('ignored, cause of no participant');
                    }
                    return false;
                }
                if (user.deadline == null || !this.getPlannedValidator()(user.deadline)) {
                    if (debug) {
                        console.warn('ignored, cause of plannedValidator');
                    }
                    return false;
                }
            }
            if (this.isSoftDeadline() !== null) {
                let user = task.findParticipant(this.getUser().id);
                if (user == null) {
                    if (debug) {
                        console.warn('ignored, cause of plannedBetween');
                    }
                    return false;
                }
                if (user.deadline == null || user.deadline.is_soft != this.isSoftDeadline()) {
                    if (debug) {
                        console.warn('ignored, cause of isSoftDeadline');
                    }
                    return false;
                }
            }
        } else if (this.getPlannedBetween()) {
            // Kræver planlægningsdato, men uden specifik user
            let hasPlannedBetween = false;
            task.findTasksUsersByType(TaskUserTypes.Participant).forEach(tasksUser => {
                if (tasksUser.deadline != null && tasksUser.deadline.isBetween(this.getPlannedBetween()[0], this.getPlannedBetween()[1])) {
                    hasPlannedBetween = true;
                }
            });
            if (!hasPlannedBetween) {
                if (debug) {
                    console.warn('ignored, cause of plannedBetween without user');
                }
                return false;
            }
        } else if (this.getPlannedValidator()) {
            // Kræver planlægningsdato, men uden specifik user
            let hasPlannedBetween = false;
            task.findTasksUsersByType(TaskUserTypes.Participant).forEach(tasksUser => {
                if (tasksUser.deadline != null && this.getPlannedValidator()(tasksUser.deadline)) {
                    hasPlannedBetween = true;
                }
            });
            if (!hasPlannedBetween) {
                if (debug) {
                    console.warn('ignored, cause of plannedValidator without user');
                }
                return false;
            }
        }

        if (this.getArchived() != null && !this.getOrArchivedSince()) {
            if (this.getArchived() && task.archived_id == 0) {
                if (debug) {
                    console.warn('ignored, cause of not archived');
                }
                return false;
            } else if (!this.getArchived() && task.archived_id > 0) {
                if (debug) {
                    console.warn('ignored, cause of is archived');
                }
                return false;
            }
        }

        if (this.getNotArchivedForUserId()) {
            const tasksUser = task.findParticipant(this.getNotArchivedForUserId());
            if (tasksUser != null && tasksUser.archived_id != undefined) {
                if (debug) {
                    console.warn('ignored, cause of "not archived for user id"', this.getNotArchivedForUserId())
                    return false;
                }
            }
        }

        if (this.getOrArchivedSince()) {
            if (task.archived) {
                if (moment(task.archived.created).toDate().getTime() < this.getOrArchivedSince().getTime()) {
                    if (debug) {
                        console.warn('ignored, cause of orArchivedSince');
                    }
                    return false;
                }
            }
        }
        if (this.getAvoidIds()) {
            if (this.getAvoidIds().includes(task.id)) {
                if (debug) {
                    console.warn('ignored, cause of avoidIds');
                }
                return false;
            }
        }
        if (this.getDeadlineBetween() && this.getHasNonDeadline() !== true) {
            const period = this.getDeadlineBetween();
            if (task.tasks_deadlines?.find(tasksDeadline => tasksDeadline.deadline?.isBetween(period[0], period[1])) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of no deadlineBetween');
                }
                return false;
            }
        }
        if (this.getHasNonDeadline() !== null && this.getHasNonDeadline() != undefined) {
            if (this.getHasNonDeadline()) {
                if ((task.tasks_deadlines?.length ?? 0) > 0) {
                    if (debug) {
                        console.warn('ignored, cause of has non deadline', this.getHasNonDeadline(), task.tasks_deadlines);
                    }
                    return false;
                }
            } else {
                if ((task.tasks_deadlines?.length ?? 0) == 0) {
                    if (debug) {
                        console.warn('ignored, cause of has non deadline', this.getHasNonDeadline(), task.tasks_deadlines);
                    }
                    return false;
                }
            }
        }
        if (this.getDeadlineValidator()) {
            let deadlines = task.tasks_deadlines
                ?.filter(tasksDeadline => tasksDeadline.deadline != null)
                ?.map(tasksDeadline => tasksDeadline.deadline) ?? [];
            if (!this.getDeadlineValidator()(task, deadlines)) {
                if (debug) {
                    console.warn('ignored, cause of deadline validator');
                }
                return false;
            }
        }
        if (this.getArchivedBetween()) {
            if (!task.archived) {
                if (debug) {
                    console.warn('ignored, cause of not archived');
                }
                return false;
            }
            let period = this.getArchivedBetween();
            let time = new Date(task.archived.created).getTime();
            if (time < period[0].getTime() || time > period[1].getTime()) {
                if (debug) {
                    console.warn('ignored, cause of archivedBetween');
                }
                return false;
            }
        }
        if (this.getCreatedBetween()) {
            if (!task.created) {
                if (debug) {
                    console.warn('ignored, cause of no created timestamp');
                }
                return false;
            }
            let period = this.getArchivedBetween();
            let time = new Date(task.created).getTime();
            if (time < period[0].getTime() || time > period[1].getTime()) {
                if (debug) {
                    console.warn('ignored, cause of createdBetween');
                }
                return false;
            }
        }
        /*if(this.getDepartment()) { Vi prøver uden dette tjek, da vi tror alle kunder arbejder i én afdeling af gangen. Så ingen grund til at hente afdeling ud hele tiden for at validere det.
            if(task.departments == null || task.departments.find(department => department.id == this.getDepartment().id) == null) {
                return false;
        }*/
        if (this.getUseGlobalFilter()) {
            let filters = AppInjector.getInjector().get(FilterGlobalService).getActiveSettings();
            if (filters.isHandUp) {
                if (task.num_hand_ups == 0) {
                    if (debug) {
                        console.warn('ignored, cause of global hand up');
                    }
                    return false;
                }
            }
            if (filters.isStarred) {
                if (task.num_stars == 0) {
                    if (debug) {
                        console.warn('ignored, cause of global starred');
                    }
                    return false;
                }
            }
            if (filters.activeStatuses.length > 0 && filters.activeStatuses.length < 4) {
                if (task.main_status == null || !filters.activeStatuses.includes(task.main_status.status_id)) {
                    if (debug) {
                        console.warn('ignored, cause of global main status');
                    }
                    return false;
                }
            }
            if (filters.activeReactionFilters?.length > 0) {
                const reactionFilterMap = new Map<number, string[]>();
                filters.activeReactionFilters.forEach(reactionFilter => {
                    if (!reactionFilterMap.has(reactionFilter.reaction_type_id)) {
                        reactionFilterMap.set(reactionFilter.reaction_type_id, []);
                    }
                    reactionFilterMap.get(reactionFilter.reaction_type_id).push(reactionFilter.value);
                });
                let validReactionFilter = true;
                reactionFilterMap.forEach((value, key) => {
                    if ((task.reactions?.filter(reaction => value.includes(reaction.value))?.length ?? 0) == 0) {
                        validReactionFilter = false;
                        return;
                    }
                });
                if (!validReactionFilter) {
                    if (debug) {
                        console.warn('ignored, cause of reaction filter');
                    }
                    return false;
                }
            }
            if (filters.activeCategories?.length > 0) {
                const categoriesMap = new Map<number, number[]>();
                filters.activeCategories.forEach(category => {
                    if (!categoriesMap.has(category.category_type_id)) {
                        categoriesMap.set(category.category_type_id, []);
                    }
                    categoriesMap.get(category.category_type_id).push(category.id);
                });
                let validCategories = true;
                categoriesMap.forEach((value, key) => {
                    if ((task.categories?.filter(category => value.includes(category.id))?.length ?? 0) == 0) {
                        validCategories = false;
                        return;
                    }
                });
                if (!validCategories) {
                    if (debug) {
                        console.warn('ignored, cause of categories : ', filters.activeCategories);
                    }
                    return false;
                }
            }
        }
        if (this.getHasHands() !== null) {
            if (this.getHasHands()) {
                if (task.num_hand_ups == 0) {
                    if (debug) {
                        console.warn('ignored, cause of global hand up');
                    }
                    return false;
                }
            }
        }
        if (this.getHasStars() !== null) {
            if (this.getHasStars()) {
                if (task.num_stars == 0) {
                    if (debug) {
                        console.warn('ignored, cause of global starred');
                    }
                    return false;
                }
            }
        }
        if (this.getCanCopy() !== null) {
            if (task.can_copy != this.getCanCopy()) {
                if (debug) {
                    console.warn('ignored, cause of canCopy');
                }
                return false;
            }
        }
        if (this.getHasMilestone() !== null) {
            if (this.getHasMilestone() === true && task.milestones?.length == 0) {
                if (debug) {
                    console.warn('ignored, cause of hasMilestones');
                }
                return false;
            } else if (this.getHasMilestone() === false && task.milestones?.length > 0) {
                if (debug) {
                    console.warn('ignored, cause of hasMilestones');
                }
                return false;
            }
        }
        if (this.getHasNotes() !== null) {
            if (this.getHasNotes() === true && task.delivery_description?.length == 0) {
                if (debug) {
                    console.warn('ignored, cause of hasNotes', this.getHasNotes(), task.delivery_description);
                }
                return false;
            } else if (this.getHasNotes() === false && task.delivery_description?.length > 0) {
                if (debug) {
                    console.warn('ignored, cause of hasNotes', this.getHasNotes(), task.delivery_description);
                }
                return false;
            }
        }
        if (this.getTaskDeadlineTypes()?.length > 0 && this.getHasNonDeadline() !== true) {
            const hasTaskDeadlineTypes = task.tasks_deadlines?.map(tasksDeadline => tasksDeadline.task_deadline_type_id);
            if (this.getTaskDeadlineTypes().find(value => hasTaskDeadlineTypes.includes(value)) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of missing taskDeadlineTypes', this.getTaskDeadlineTypes());
                }
                return false;
            }
        }
        if (this.getOpen() !== null) {
            if (task.open != this.getOpen()) {
                if (debug) {
                    console.warn('ignored, cause of open');
                }
                return false;
            }
        }
        if (this.getCategoryIds()) {
            const categoryIds = this.getCategoryIds();
            if (task.categories?.find(category => categoryIds.includes(category.id)) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of category ids', task.categories, categoryIds);
                }
                return false;
            }
        }
        if (this.getSort_CategoryTypeId()) {
            const sortCategoryTypeId = this.getSort_CategoryTypeId();
            if (task.categories?.find(category => category.category_type_id == sortCategoryTypeId) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of sort category type id', task.categories, sortCategoryTypeId);
                }
                return false;
            }
        }
        if (this.getSort_DeadlineTypeId()) {
            const sortDeadlineTypeId = this.getSort_DeadlineTypeId();
            if (task.tasks_deadlines?.find(tasksDeadline => tasksDeadline.task_deadline_type_id == sortDeadlineTypeId) === undefined) {
                if (debug) {
                    console.warn('ignored, cause of sort deadline type id', task.tasks_deadlines, sortDeadlineTypeId);
                }
                return false;
            }
        }
        if (this.getSort_DeadlineValidator()) {
            if (!this.getSort_DeadlineValidator()(task)) {
                if (debug) {
                    console.warn('ignored, cause of sort deadline validator');
                }
                return false;
            }
        }
        if (this.getMainStatusStatusIds()) {
            if (!this.getMainStatusStatusIds().includes(task.main_status?.status_id ?? 0)) {
                if (debug) {
                    console.warn('ignored, cause of main status status ids', this.getMainStatusStatusIds(), task.main_status);
                }
                return false;
            }
        }

        return true;
    }

    // <editor-fold desc="Create Item">

    prepareSource(): void {
        const taskTypes: number[] = [];
        if (this.getTaskType()) {
            taskTypes.push(this.getTaskType().id);
        } else if (this.getTaskTypeIds()) {
            taskTypes.push(...this.getTaskTypeIds());
        }
        if (taskTypes.length) {
            this.createItemConfiguration.filterTaskTypesById = taskTypes;
        }
        if (this.getAvoidTaskTypeIds()) {
            this.createItemConfiguration.filterByAvoidTaskTypeIds = this.getAvoidTaskTypeIds();
        }

        // https://podio.com/klartboard/softwareudvikling/apps/supports/items/355
        this.createItemConfiguration.filterByDepartmentIds = AppInjector.getInjector().get(UsersService).user?.departments?.map(d => d.id) || [];

        if (this.getPrepareCreateItemSourceHook()) {
            this.getPrepareCreateItemSourceHook()(this.createItemConfiguration);
        }
    }

    public createPresets(typeId?: number, options?: any): CreatePreset[] {
        return this.createPresetGenerators?.map(generator => generator.generate(typeId, options)) ?? [];
    }

    // </editor-fold>

    // <editor-fold desc="Data Listeners">

    protected dataSourceSubscriptions?: Subscription;

    protected setupDataSource() {
        if (this.getDataSource()) {
            this.dataSourceSubscriptions?.unsubscribe();
            this.dataSourceSubscriptions = new Subscription();
            this.dataSourceSubscriptions.add(
                this.getDataSource().subscribeToItems((tasks: Task[]) => {
                    const items = tasks.map(task => this.createCard(task));
                    this.onAddItemsEvent.emit({items: items});
                    this.setLastItemCount(items.length);
                })
            );
            this.dataSourceSubscriptions.add(
                this.getDataSource().subscribeToCount((count: number) => {
                    this.onCountEvent.emit({count: count});
                })
            );
        }
    }

    public createCard(item: Task): CardItem<Task> {
        const cardItem = new CardItem<Task>(item, new CardTaskConfiguration());
        this.applyCardConfiguration(cardItem);
        return cardItem;
    }

    private applyCardConfiguration(card: CardItem<Task>) {
        const config = card.configuration as unknown as CardTaskConfiguration;
        config.displayModeMini = this.getCardDisplayModeMini();
        config.taskEstimate = card.item.findTaskEstimateByType(this.getShownEstimateTypeId());
        config.shownEstimateTypeId = this.getShownEstimateTypeId();
        config.isDraggable = this.isDraggable();
        config.showChangeType = this.showChangeType;
        config.showProjectMiniCard = this.getShowProjectMiniCard();
        config.showMilestoneMiniCards = this.getShowMilestoneMiniCard();
        config.excludedCategoryIds = this.cardConfiguration_excludedCategoryIds;
        config.useGlobalFilter = this.useGlobalFilter;
        if (this.getBeforeAddHook()) {
            this.getBeforeAddHook()(card);
        }
        card.setListener(this);
    }

    // </editor-fold>

    // <editor-fold desc="Push">

    private pushSubscription: Subscription;

    public pushSubscribe() {
        this.pushSubscription = new Subscription();
        this.pushSubscription.add(AppInjector.getInjector().get(EventService).subscribeToTask(0, event => {
            switch (event.action) {
                case EventService.Created:
                    if (this.validate(event.item)) {
                        const card = this.createCard(event.item);
                        this.onAddItemEvent.emit({item: card});
                    }
                    break;
                case EventService.Updated:
                    const itemsToUpdate = this.dataSource?.getItemsById(event.item.id);
                    if (itemsToUpdate?.length > 0) {
                        itemsToUpdate.forEach(item => {
                            item.populate({...event.item}, event.isPatch);
                            const card = this.createCard(item);
                            if (!this.validate(item)) {
                                this.onRemoveItemEvent.emit({item: card});
                                this.onItemRemovedEvent.emit(card);
                            } else {
                                this.onItemUpdatedEvent.emit(card);
                            }
                        });
                    } else if (this.validate(event.item)) {
                        const card = this.createCard(event.item);
                        this.onAddItemEvent.emit({item: card});
                    }
                    break;
                case EventService.Deleted:
                    const card = this.createCard(event.item);
                    this.onRemoveItemEvent.emit({item: card});
                    this.onItemRemovedEvent.emit(card);
                    break;
            }
        }));
    }

    public pushUnsubscribe() {
        this.pushSubscription?.unsubscribe();
    }

    // </editor-fold>

    // <editor-fold desc="Card Interface">

    public onCardItemDragged(cardItem: CardItem<Task>, listConfiguration: ListConfiguration, toContainerId: string, fromContainerId: string, cdkDropEvent: CdkDropEvent<Task>): void {

    }

    public onCardItemUpdated(cardItem: CardItem<Task>): void {
        if (!this.validate(cardItem.item)) {
            this.onRemoveItemEvent.emit({item: cardItem});
            this.onItemRemovedEvent.emit(cardItem);
        }
    }

    public onCardItemDeleted(cardItem: CardItem<Task>): void {
        this.onRemoveItemEvent.emit({item: cardItem});
        this.onItemRemovedEvent.emit(cardItem);
    }

    // </editor-fold>

    // <editor-fold desc="ListDragInterface">

    public allowCardItemEnter(cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>): boolean {
        if (!(cardItem.item instanceof Task)) {
            return false;
        }

        if (this.getCustomAllowCardItemEnterFunction()) {
            return this.getCustomAllowCardItemEnterFunction()(cardItem, fromListConfiguration);
        } else {
            return super.allowCardItemEnter(cardItem, fromListConfiguration);
        }
    }

    public allowCardItemDrop(cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>, result: (allow: boolean) => void): void {
        if (!(cardItem.item instanceof Task)) {
            result(false);
            return;
        }

        if (this.getCustomAllowCardItemDropFunction()) {
            this.getCustomAllowCardItemDropFunction()(cardItem, fromListConfiguration, result);
        } else {
            result(true);
        }
    }

    public onCardItemDragAdd(cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>,
                             callback: (() => void), previousIndex?: number, nextIndex?: number): void {
        if (!(cardItem.item instanceof Task)) {
            return;
        }

        const card = cardItem as unknown as CardItem<Task>;
        const configuration = fromListConfiguration as unknown as TaskListConfiguration;

        if (this.getCustomOnCardItemDragAddFunction()) {

            this.getCustomOnCardItemDragAddFunction()(card, configuration, previousIndex, nextIndex);

        } else {

            if (cardItem.item.can_copy) { // https://podio.com/klartboard/softwareudvikling/apps/stories/items/702
                Api.tasks().copyGetById(cardItem.item.id).find(tasks => {
                    const newCardItem = this.createCard(tasks[0]);
                    this.defaultDragAndDropFunction(newCardItem, configuration, callback);
                    this.onAddItemEvent.emit({item: this.createCard(newCardItem.item)});
                    callback();
                });
                return;
            } else {
                this.defaultDragAndDropFunction(card, configuration, callback, nextIndex);
            }

        }

        this.onAddItemEvent.emit({item: this.createCard(cardItem.item), index: nextIndex});
        callback();
    }

    private defaultDragAndDropFunction(cardItem: CardItem<Task>, fromListConfiguration: TaskListConfiguration,
                                       callback: (() => void), index?: number) {
        const task = cardItem.item;

        if (this.getUser()) {

            const fromUser = fromListConfiguration.getUser();
            const toUser = this.getUser();

            const ensureUser = (finish: () => void) => {
                if (fromUser && fromUser.id !== toUser.id) {
                    // Moved between users
                    task.replaceUser(TaskUserTypes.Participant, fromUser, toUser, finish);
                } else if (!fromUser) {
                    // Moved from non-user to user
                    task.addUser(TaskUserTypes.Participant, toUser, finish);
                } else {
                    // Moved between same user
                    finish();
                }
            };

            const getDate = (): Date | undefined => {
                if (this.getPlannedBetween()) {
                    return this.getPlannedBetween()[0];
                } else if (this.getPlannedValidatorDate()) {
                    return this.getPlannedValidatorDate()();
                } else if (this.getDeadlineBetween()) {
                    return this.getDeadlineBetween()[0];
                }
            }

            const setPlanningDate = (date: Date, isSoft: boolean) => {
                // Tjek om der er andre deltagere end den konfigurerede bruger
                const participants = task.findUsersByTypeId(TaskUserTypes.Participant);
                if (participants.length == 1) {
                    task.setUserDeadline(participants[0], date, isSoft);
                } else if (participants.length > 1) {
                    // Dialog: "Vil du ændre planlægningsdatoen for alle deltagere?"
                    AppInjector.getInjector().get(BaseDialogService).confirm(
                        AppInjector.getInjector().get(TranslateService).instant('_ui_dialog_more_participants'),
                        AppInjector.getInjector().get(TranslateService).instant(
                            '_ui_dialog_more_participants_description',
                            {date: moment(date).format('DD/MM-YYYY')}
                        )
                    ).then(confirmed => {
                        if (confirmed) {
                            participants.forEach(participant => {
                                cardItem.item.setUserDeadline(participant, date, isSoft);
                            });
                        } else {
                            AppInjector.getInjector().get(EventService).emitTask(task, EventService.Updated, [
                                'tasks_users',
                            ]);
                        }
                    });
                }
            }

            ensureUser(() => {
                const date = getDate();
                if (date) {
                    setPlanningDate(date, this.isSoftDeadline());
                }
            });
        }

        if (this.getDeadlineBetween()) { // https://podio.com/klartboard/softwareudvikling/apps/stories/items/50
            const taskDeadlineTypeId = this.getTaskDeadlineTypes()?.length
                ? this.getTaskDeadlineTypes()[0]
                : TaskDeadlineTypes.Normal;
            task.setDeadline(taskDeadlineTypeId, Deadline.Create(this.getDeadlineBetween()[0]));
        }
    }

    public onCardItemDragRemove(cardItem: CardItem<T>): void {
        if (!(cardItem.item instanceof Task)) return;
        const card = cardItem as unknown as CardItem<Task>;

        if (card.item.can_copy) {
            return; // CanCopy should not be removed. https://podio.com/klartboard/softwareudvikling/apps/stories/items/702
        }

        if (!this.getCardItemDragRemoveValidator() || this.getCardItemDragRemoveValidator()(card)) {
            this.onRemoveItemEvent.emit({item: card});
            this.onItemRemovedEvent.emit(card);
        }
    }

    public onCardItemDragSaved(): void {
        if (this.getCustomOnCardDragSavedFunction()) {
            this.getCustomOnCardDragSavedFunction()();
        }
    }

    // </editor-fold>

    // <editor-fold desc="CSV Export">

    public csvExport(item: CardItem<Task>, option?: CSVExportOptionInterface): any {
        return item.item.title;
    }

    // </editor-fold>

    // <editor-fold desc="Get / Set">

    public getOrderBy(): string[][] {
        return this.orderBy;
    }

    public setOrderBy(value: string[][]): TaskListConfiguration<T> {
        this.orderBy = value;
        return this;
    }

    public getLimit(): number {
        return super.getLimit();
    }

    public setLimit(value: number): TaskListConfiguration<T> {
        super.setLimit(value);
        return this;
    }

    public getUser(): User {
        return this.user;
    }

    public setUser(value: User): TaskListConfiguration<T> {
        this.user = value;
        return this;
    }

    public getMilestone(): Milestone {
        return this.milestone;
    }

    public setMilestone(value: Milestone): TaskListConfiguration<T> {
        this.milestone = value;
        return this;
    }

    public getArchived(): boolean {
        return this.archived;
    }

    public setArchived(value: boolean): TaskListConfiguration<T> {
        this.archived = value;
        return this;
    }

    public getRequireRelatedMilestone(): boolean {
        return this.requireRelatedMilestone;
    }

    public setRequireRelatedMilestone(value: boolean): TaskListConfiguration<T> {
        this.requireRelatedMilestone = value;
        return this;
    }

    public getNotArchivedForUserId(): number {
        return this.notArchivedForUserId;
    }

    public setNotArchivedForUserId(value: number): TaskListConfiguration<T> {
        this.notArchivedForUserId = value;
        return this;
    }

    public getProject(): Project {
        return this.project;
    }

    public setProject(value: Project): TaskListConfiguration<T> {
        this.projects = [value];
        this.project = value;
        return this;
    }

    public getProjects(): Project[] {
        return this.projects;
    }

    public setProjects(value: Project[]): TaskListConfiguration<T> {
        this.projects = value;
        return this;
    }

    public getAvoidIds(): number[] {
        return this.avoidIds;
    }

    public setAvoidIds(value: number[]): TaskListConfiguration<T> {
        this.avoidIds = value;
        return this;
    }

    public getTaskType(): TaskType {
        return this.taskType;
    }

    public setTaskType(value: TaskType): TaskListConfiguration<T> {
        this.taskType = value;
        return this;
    }

    public getPlannedBetween(): Date[] {
        return this.plannedBetween;
    }

    public setPlannedBetween(start: Date, end: Date): TaskListConfiguration<T> {
        this.plannedBetween = [start, end];
        return this;
    }

    public getPlannedValidator(): (deadline: Deadline) => boolean {
        return this.plannedValidator;
    }

    public setPlannedValidator(value: ((deadline: Deadline) => boolean)): TaskListConfiguration<T> {
        this.plannedValidator = value;
        return this;
    }

    public getPlannedValidatorDate(): () => Date {
        return this.plannedValidatorDate;
    }

    public setPlannedValidatorDate(value: () => Date): TaskListConfiguration<T> {
        this.plannedValidatorDate = value;
        return this;
    }

    public getDeadlineBetween(): Date[] {
        return this.deadlineBetween;
    }

    public setDeadlineBetween(start: Date, end: Date): TaskListConfiguration<T> {
        this.deadlineBetween = [start, end];
        return this;
    }

    public getDeadlineValidator(): (task: Task, deadlines: Deadline[]) => boolean {
        return this.deadlineValidator;
    }

    public setDeadlineValidator(value: ((task: Task, deadlines: Deadline[]) => boolean)): TaskListConfiguration<T> {
        this.deadlineValidator = value;
        return this;
    }

    public getHasNonDeadline(): boolean {
        return this.hasNonDeadline;
    }

    public setHasNonDeadline(value: boolean): TaskListConfiguration<T> {
        this.hasNonDeadline = value;
        return this;
    }

    public getCreatedBetween(): Date[] {
        return this.createdBetween;
    }

    public setCreatedBetween(start: Date, end: Date): TaskListConfiguration<T> {
        this.createdBetween = [start, end];
        return this;
    }

    public getArchivedBetween(): Date[] {
        return this.archivedBetween;
    }

    public setArchivedBetween(start: Date, end: Date): TaskListConfiguration<T> {
        if (start != null && end != null) {
            this.archivedBetween = [start, end];
        } else {
            this.archivedBetween = null;
        }
        return this;
    }

    public getAvoidTaskTypeIds(): number[] {
        return this.avoidTaskTypeIds;
    }

    public setAvoidTaskTypeIds(value: number[]): TaskListConfiguration<T> {
        this.avoidTaskTypeIds = value;
        return this;
    }

    public getShownEstimateTypeId(): number {
        return this.shownEstimateTypeId;
    }

    public setShownEstimateTypeId(value: number): TaskListConfiguration<T> {
        this.shownEstimateTypeId = value;
        return this;
    }

    public setEnableDragDropAutoMove(value: boolean): TaskListConfiguration<T> {
        this.enableDragDropAutoMove = value;
        return this;
    }

    public getDepartment(): Department {
        return this.department;
    }

    public setDepartment(value: Department): TaskListConfiguration<T> {
        this.department = value;
        return this;
    }

    public getUseGlobalFilter(): boolean {
        return this.useGlobalFilter;
    }

    public setUseGlobalFilter(value: boolean): TaskListConfiguration<T> {
        this.useGlobalFilter = value;
        return this;
    }

    public isSoftDeadline(): boolean {
        return this.softDeadline;
    }

    public setSoftDeadline(value: boolean): TaskListConfiguration<T> {
        this.softDeadline = value;
        return this;
    }

    public getSearch(): string {
        return this.search;
    }

    public setSearch(value: string): TaskListConfiguration<T> {
        this.search = value;
        return this;
    }

    public getCardDisplayModeMini(): boolean {
        return this.cardIsMini;
    }

    public setCardDisplayModeMini(cardIsMini: boolean): TaskListConfiguration<T> {
        this.cardIsMini = cardIsMini;
        return this;
    }

    public getDataSource(): TaskFetchRequest {
        return this.dataSource;
    }

    public setDataSource(value: TaskFetchRequest): TaskListConfiguration<T> {
        this.dataSource = value;
        this.setupDataSource();
        return this;
    }

    public getShowProjectMiniCard(): boolean {
        return this.showProjectMiniCard;
    }

    public setShowProjectMiniCard(value: boolean): TaskListConfiguration<T> {
        this.showProjectMiniCard = value;
        return this;
    }

    public getShowMilestoneMiniCard(): boolean {
        return this.showMilestoneMiniCard;
    }

    public setShowMilestoneMiniCard(value: boolean): TaskListConfiguration<T> {
        this.showMilestoneMiniCard = value;
        return this;
    }

    public getOrArchivedSince(): Date {
        return this.orArchivedSince;
    }

    public setOrArchivedSince(value: Date): TaskListConfiguration<T> {
        this.orArchivedSince = value;
        return this;
    }

    public getUserTypeId(): number {
        return this.userTypeId;
    }

    public setUserTypeId(value: number): TaskListConfiguration<T> {
        this.userTypeId = value;
        return this;
    }

    public getAcceptanceStatus(): number[] {
        return this.acceptanceStatus;
    }

    public setAcceptanceStatus(value: number[]): TaskListConfiguration<T> {
        this.acceptanceStatus = value;
        return this;
    }

    public getPlanningDeadlineId(): number {
        return this.planningDeadlineId;
    }

    public setPlanningDeadlineId(value: number): TaskListConfiguration<T> {
        this.planningDeadlineId = value;
        return this;
    }

    public getCardItemDragRemoveValidator(): ((cardItem: CardItem<Task>) => boolean) {
        return this.cardItemDragRemoveValidator;
    }

    public setCardItemDragRemoveValidator(value: ((cardItem: CardItem<Task>) => boolean)): TaskListConfiguration<T> {
        this.cardItemDragRemoveValidator = value;
        return this;
    }

    public getBeforeAddHook(): ((item: CardItem<Task>) => void) {
        return this.beforeAddHook;
    }

    public setBeforeAddHook(value: ((item: CardItem<Task>) => void)): TaskListConfiguration<T> {
        this.beforeAddHook = value;
        return this;
    }

    public getCustomOnCardItemDragAddFunction(): ((cardItem: CardItem<Task>, fromConfiguration: TaskListConfiguration, previousIndex?: number, nextIndex?: number) => void) {
        return this.customOnCardItemDragAddFunction;
    }

    public setCustomOnCardItemDragAddFunction(value: ((cardItem: CardItem<Task>, fromConfiguration: TaskListConfiguration, previousIndex?: number, nextIndex?: number) => void)): TaskListConfiguration<T> {
        this.customOnCardItemDragAddFunction = value;
        return this;
    }

    public getCustomAllowCardItemDropFunction(): ((cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>, result: (allow: boolean) => void) => void) {
        return this.customAllowCardItemDropFunction;
    }

    public setCustomAllowCardItemDropFunction(value: ((cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>, result: (allow: boolean) => void) => void)): TaskListConfiguration<T> {
        this.customAllowCardItemDropFunction = value;
        return this;
    }

    public getCustomAllowCardItemEnterFunction(): ((cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>) => boolean) {
        return this.customAllowCardItemEnterFunction;
    }

    public setCustomAllowCardItemEnterFunction(value: ((cardItem: CardItem<T>, fromListConfiguration: TaskListConfiguration<T>) => boolean)): TaskListConfiguration<T> {
        this.customAllowCardItemEnterFunction = value;
        return this;
    }

    public getCustomOnCardDragSavedFunction(): () => void {
        return this.customOnCardItemDragSavedFunction;
    }

    public setCustomOnCardDragSavedFunction(value: () => void): TaskListConfiguration<T> {
        this.customOnCardItemDragSavedFunction = value;
        return this;
    }

    public getPrepareCreateItemSourceHook(): (createItemSourceHook: CreateItemSourceConfiguration) => void {
        return this.prepareCreateItemSourceHook;
    }

    public setPrepareCreateItemSourceHook(value: (createItemSourceHook: CreateItemSourceConfiguration) => void): TaskListConfiguration<T> {
        this.prepareCreateItemSourceHook = value;
        return this;
    }

    public isEnabled(): boolean {
        return this.enabled;
    }

    public setEnabled(value: boolean): TaskListConfiguration<T> {
        this.enabled = value;
        return this;
    }

    public setModelClass(value: string): TaskListConfiguration<T> {
        super.setModelClass(value);
        return this;
    }

    public setUseGlobalSearchFilter(value: boolean): TaskListConfiguration<T> {
        super.setUseGlobalSearchFilter(value);
        return this;
    }

    public getCanCopy(): boolean {
        return this.canCopy;
    }

    public setCanCopy(value: boolean): TaskListConfiguration<T> {
        this.canCopy = value;
        return this;
    }

    public getHasMilestone(): boolean {
        return this.hasMilestone;
    }

    public setHasMilestones(value: boolean): TaskListConfiguration<T> {
        this.hasMilestone = value;
        return this;
    }

    public getHasNotes(): boolean {
        return this.hasNotes;
    }

    public setHasNotes(value: boolean): TaskListConfiguration<T> {
        this.hasNotes = value;
        return this;
    }

    public getTaskDeadlineTypes(): number[] {
        return this.taskDeadlineTypes;
    }

    public setTaskDeadlineTypes(value: number[]): TaskListConfiguration<T> {
        this.taskDeadlineTypes = value;
        return this;
    }

    public getTaskTypeIds(): number[] {
        return this.taskTypeIds;
    }

    public setTaskTypeIds(value: number[]): TaskListConfiguration<T> {
        this.taskTypeIds = value;
        return this;
    }

    public getOpen(): boolean {
        return this.open;
    }

    public setOpen(value: boolean): TaskListConfiguration<T> {
        this.open = value;
        return this;
    }

    public getCategoryIds(): number[] {
        return this.categoryIds;
    }

    public setCategoryIds(value: number[]): TaskListConfiguration<T> {
        this.categoryIds = value;
        return this;
    }

    public getCardConfiguration_ExcludedCategoryIds(): number[] {
        return this.cardConfiguration_excludedCategoryIds;
    }

    public setCardConfiguration_ExcludedCategoryIds(value: number[]): TaskListConfiguration<T> {
        this.cardConfiguration_excludedCategoryIds = value;
        return this;
    }

    public getMainStatusStatusIds(): number[] {
        return this.mainStatusStatusIds;
    }

    public setMainStatusStatusIds(value: number[]): TaskListConfiguration<T> {
        this.mainStatusStatusIds = value;
        return this;
    }

    public setIsSortingEnabled(value: boolean): TaskListConfiguration<T> {
        this.isSortingEnabled = value;
        return this;
    }

    public setHasHands(value: boolean | null): TaskListConfiguration<T> {
        this.hasHands = value;
        return this;
    }

    public getHasHands(): boolean | null {
        return this.hasHands;
    }

    public setHasStars(value: boolean | null): TaskListConfiguration<T> {
        this.hasStars = value;
        return this;
    }

    public getHasStars(): boolean | null {
        return this.hasStars;
    }

    // </editor-fold>

    // <editor-fold desc="Sort Filters (When a sort require a filter)">

    public getSort_CategoryTypeId(): number {
        return this.sort_CategoryTypeId;
    }

    public setSort_CategoryTypeId(value: number): TaskListConfiguration<T> {
        this.sort_CategoryTypeId = value;
        return this;
    }

    public getSort_DeadlineTypeId(): number {
        return this.sort_DeadlineTypeId;
    }

    public setSort_DeadlineTypeId(value: number): TaskListConfiguration<T> {
        this.sort_DeadlineTypeId = value;
        return this;
    }

    public getSort_DeadlineValidator(): (task: Task) => boolean {
        return this.sort_DeadlineValidator;
    }

    public setSort_DeadlineValidator(value: ((task: Task) => boolean)): TaskListConfiguration<T> {
        this.sort_DeadlineValidator = value;
        return this;
    }

    public clearSortFilters() {
        this.setSort_CategoryTypeId(undefined);
        this.setSort_DeadlineTypeId(undefined);
        this.setSort_DeadlineValidator(undefined);
    }

    public applySortFiltersToApi(api: BaseApi) {
        if (this.getSort_CategoryTypeId()) {
            api.where('category.category_type_id', this.getSort_CategoryTypeId());
        }
    }

    // </editor-fold>

}
